﻿document.addEventListener( "DOMContentLoaded", function ( event ) {
    handleNavigation();
    accordions();
    bindRapidoHookProductListLoaded();
} );

function bindRapidoHookProductListLoaded() {
    var _hideStaticProductLists = function() {
        document.querySelectorAll( '.js-thermex-product-list-static' ).forEach( domProductList => {
            domProductList.classList.add( 'u-hidden' );
        } );
    };
    RapidoHook.productListLoaded( function () {
        _hideStaticProductLists();
    } );
}


function accordions() {
    document.querySelectorAll('.js-group-field-title').forEach(item => {
        item.addEventListener('click', function (event) {
            if (event.target.classList.contains('-opened')) {
                event.target.classList.remove('-opened');
            }
            else {
                event.target.classList.add('-opened');
            }
        });
    })
}

function handleNavigation() {
    var lastScrollTop;
    var navbar = document.getElementsByClassName('main-navigation')[0];

    if (navbar === undefined) {
        return;
    }

    window.addEventListener('scroll', function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > 85 && scrollTop > lastScrollTop) {
            navbar.classList.add('-hide');
        }
        else {
            navbar.classList.remove('-hide');
        }

        lastScrollTop = scrollTop;
    });
}

function getQuoteLink(cartId, url) {
    preloader(true);

    var link = '';
    var elLink = document.getElementById('j-products-link');

    if (elLink.innerHTML !== '') {
        copyToClipboard(elLink.innerHTML);
        preloader(false);
    } else {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "/api/clonecart/" + cartId, true);
        xhr.onreadystatechange = function () {
            var errorMessage = document.getElementById('j-products-link-container').dataset.error;

            if (xhr.readyState === 4 && xhr.status === 200) {
                var response = JSON.parse(xhr.responseText);

                if (response.Success === 'False') {
                    elLink.innerHTML = errorMessage;
                } else {
                    link = url + '/api/addtocart/' + response.id;
                    elLink.innerHTML = link;
                    elLink.classList.add("-bg");
                    copyToClipboard(link);
                }
            } else {
                elLink.innerHTML = errorMessage;
            }
            preloader(false);
        }
        xhr.send();
    }


}

function copyToClipboard(text) {
    var elLink = document.getElementById('j-products-link');
    var input = elLink.appendChild(document.createElement("input"));
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
}

function preloader(preloader) {
    if (preloader) {
        var overlayElement = document.createElement('div');
        overlayElement.className = "preloader-overlay";
        overlayElement.setAttribute('id', "preloader");
        var overlayElementIcon = document.createElement('div');
        overlayElementIcon.className = "preloader-overlay__icon dw-mod";
        overlayElementIcon.style.top = window.pageYOffset + "px";
        overlayElement.appendChild(overlayElementIcon);

        document.getElementById('content').parentNode.insertBefore(overlayElement, document.getElementById('content'));
    } else {
        document.querySelector("#preloader").remove();
    }
}

function stopYoutube() {
    var iframes = document.getElementsByClassName('js-video-iframe');

    if (!iframes.length) {
        return;
    }

    Array.from(document.getElementsByClassName('js-video-iframe')).forEach((e) => {
        e.innerHTML = e.innerHTML;
    });
}

function loadYoutube() {
    if (typeof Cookiebot === 'undefined' || Cookiebot.consent.marketing) {
        Array.from(document.getElementsByClassName('js-video')).forEach((e) => {
            e.setAttribute('src', e.getAttribute('data-src'));
        });
    }
}

function sliderRange() {
    document.addEventListener('contentLoaded', function (event) {
        if (event.detail.containerId == 'productList') {
            if (document.getElementsByClassName('slider').length <= 0) {
                return;
            }

            var browserParams = new QueryArray(window.location.href);

            $('.slider').each(function () {
                var $this = $(this);
                var id = $this.attr("id");
                var min = parseInt($this.attr("data-min"));
                var max = parseInt($this.attr("data-max"));
                var inputMin = $("input[name=" + id + "_min]");
                var inputMax = $("input[name=" + id + "_max]");
                var queryMin = browserParams.queryArray.Width === undefined ? min : browserParams.queryArray.Width.split(',')[0];
                var queryMax = browserParams.queryArray.Width === undefined ? max : browserParams.queryArray.Width.split(',')[1];

                inputMin.val(queryMin);
                $("input[name=" + id + "_max]").val(queryMax);

                $this.slider({
                    range: true,
                    min: min,
                    max: max,
                    values: [queryMin, queryMax],
                    slide: function (event, ui) {
                        var ui1 = parseInt(ui.values[0]);
                        var ui2 = parseInt(ui.values[1]);

                        inputMin.val(ui1);
                        inputMax.val(ui2);
                    }
                });

                $this.on("slidestop", function (event, ui) {
                    var ui1 = parseInt(ui.values[0]);
                    var ui2 = parseInt(ui.values[1]);
                    var obj = {};
                    obj[id] = ui1 + "," + ui2;

                    HandlebarsBolt.UpdateQueryParameters('productList', obj, true);
                });

                inputMin.change(function () {
                    var minVal = inputMin.val() == "" ? min : parseInt(inputMin.val()) < 0 || parseInt(inputMin.val()) > max ? max : inputMin.val();
                    var maxVal = inputMax.val() == "" ? max : parseInt(inputMax.val()) < minVal || parseInt(inputMax.val()) > max ? max : inputMax.val();
                    $this.slider("values", [minVal, maxVal]);
                    var obj = {};
                    obj[id] = minVal + "," + maxVal;
                    HandlebarsBolt.UpdateQueryParameters('productList', obj, true);
                });
                inputMax.change(function () {
                    var minVal = inputMin.val() == "" ? min : parseInt(inputMin.val()) < 0 || parseInt(inputMin.val()) > max ? max : inputMin.val();
                    var maxVal = inputMax.val() == "" ? max : parseInt(inputMax.val()) < minVal || parseInt(inputMax.val()) > max ? max : inputMax.val();
                    $this.slider("values", [minVal, maxVal]);
                    var obj = {};
                    obj[id] = minVal + "," + maxVal;
                    HandlebarsBolt.UpdateQueryParameters('productList', obj, true);
                });
            });
        }
    });
}

function submitWarranty(event) {
    event.preventDefault();

    var fields = ['ProductName', 'ItemNumber', 'SeriesNumber', 'DateOfPurchase', 'ThermexOrderNumber'];
    var addToCartString = document.getElementById("productDetailAddToCart").getAttribute("addtocart");

    fields.forEach((i) => {
        var fieldValue = document.getElementById(i).value;
        addToCartString = addToCartString.replace("#" + i + "#", fieldValue);
    });

    // Upload invoice file
    var data = new FormData();
    data.append("file", document.getElementById('InvoiceCopy').files[0]);
    fetch('/api/files/uploadfile/', {
        method: 'POST',
        body: data
    }).then(
        response => response.json()
    ).then(
        success => {
            if (success.Success === 'False') {
                alert(success.responseText);
            }
            else {
                document.querySelector("#WarrantyProductBlockComponentModalTrigger").checked = false;
                addToCartString = addToCartString.replace("#InvoiceCopy#", success.url);

                // Add to cart
                setTimeout(() => {
                    eval(addToCartString);
                }, 200);
            }
        }
    ).catch(
        error => console.log(error)
    );
}

async function submitWarrantyOnCart(event) {
    event.preventDefault();
    var modalFakeOrderLineFields = document.getElementsByClassName('js-order-line');
    var isToSubmit = true;

    for (var i = 0; i < modalFakeOrderLineFields.length; i++) {
        var field = modalFakeOrderLineFields.item(i);
        var value = field.value;
        var orderLineFieldId = field.getAttribute('id').replace('f_', '');

        if (orderLineFieldId.includes('InvoiceCopy')) {
            if (value == '') {
                continue;
            }
            else {
                // Upload invoice file
                var data = new FormData();
                data.append("file", field.files[0]);
                await fetch('/api/files/uploadfile/', {
                    method: 'POST',
                    body: data
                }).then(
                    response => response.json()
                ).then(
                    success => {
                        if (success.Success === 'False') {
                            isToSubmit = false;
                            alert(success.responseText);
                        }
                        else {
                            document.getElementById(orderLineFieldId).value = success.url;
                        }
                    }
                ).catch(
                    error => console.log(error)
                );
            }
        }
        else {
            document.getElementById(orderLineFieldId).value = value;
        }
    }

    if (isToSubmit) { 
        document.getElementById("CheckoutForm").submit();
    }
}

function anmeldServiceForm(e) {

    // Show all the fields that must be present for all product types
    function showRegularFields() {
        var fieldGroups = document.getElementsByClassName('form__field-group');
        for (var i = 0; i < fieldGroups.length; i++) {
            var field = fieldGroups[i];
            if (!field.classList.contains('js-no-red') && !field.classList.contains('js-no-orange') && !field.classList.contains('dealer-field') && !field.classList.contains('js-thermex-service-form-file-type-msg') && !field.classList.contains('js-thermex-service-form-file-size-msg')) {
                field.classList.remove("u-hidden");
            }
        }
    }

    var orangeFields = document.getElementsByClassName('js-no-orange');
    var redFields = document.getElementsByClassName('js-no-red');
    var submitButton = document.getElementsByClassName('submit-btn')[0];

    submitButton.classList.remove("u-hidden");

    // Show/Hide fields per product type
    switch (e.value) {
        // Red
        case "OtherThings":
        case "Fan":
            showRegularFields();

            for (var i = 0; i < orangeFields.length; i++) {
                var field = orangeFields[i];
                field.classList.remove("u-hidden");
            }

            for (var i = 0; i < redFields.length; i++) {
                var field = redFields[i];
                field.classList.add("u-hidden");
            }

            break;
        // Green
        case "ExtractorHood":
            showRegularFields();

            for (var i = 0; i < orangeFields.length; i++) {
                var field = orangeFields[i];
                field.classList.remove("u-hidden");
            }

            for (var i = 0; i < redFields.length; i++) {
                var field = redFields[i];
                field.classList.remove("u-hidden");
            }
            break;

        // Orange
        case "Hob":
        case "Oven":
        case "HeatPump":
        case "WineRefrigerator":
            showRegularFields();

            for (var i = 0; i < redFields.length; i++) {
                var field = redFields[i];
                field.classList.remove("u-hidden");
            }

            for (var i = 0; i < orangeFields.length; i++) {
                var field = orangeFields[i];
                field.classList.add("u-hidden");
            }

            break;

        default:
            var fieldGroups = document.getElementsByClassName('form__field-group');
            for (var i = 0; i < fieldGroups.length; i++) {
                var field = fieldGroups[i];
                if (!field.classList.contains('productType-group')) {
                    field.classList.add("u-hidden");
                }
            }
            submitButton.classList.add("u-hidden");
    }
}

function isADealer(e) {

    function verifyRequiredFields(e, isToRequire) {
        if (e.classList.contains('dealer-required')) {
            var inputField = e.getElementsByTagName('input')[0];

            if (inputField !== null && typeof inputField !== 'undefined') {
                if (isToRequire) {
                    inputField.setAttribute('required', true);
                }
                else {
                    inputField.removeAttribute('required');
                }

                var label = e.getElementsByTagName('label')[0];

                if (!label.getElementsByTagName('span').length) {
                    label.innerHTML = label.innerHTML + ' <span class="required dw-mod">*</span>';
                }
            }
        }
    }

    var fieldGroups = document.getElementsByClassName('dealer-field');

    for (var i = 0; i < fieldGroups.length; i++) {
        var field = fieldGroups[i];
        if (e.checked == true) {
            field.classList.remove("u-hidden");
        }
        else {
            field.classList.add("u-hidden");
        }

        verifyRequiredFields(field, e.checked);
    }

}

// #26929 Productlist SEO text in HTML
document.addEventListener('contentLoaded', (event) => {
    if (event != null && event.detail != null && event.detail.containerId != null && event.detail.containerId == 'productList') {
        document.querySelectorAll('.js-handlebars-remove').forEach((el) => {
            el.remove();
        });
    }
});

// #27042 Mask email addresses
document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(".js-mask").forEach((el) => {
        let email = el.getAttribute("data-mask").replace("[dot]", ".");
        let emailHTML = email.replace("[at]", '<i class="fa fa-at u-fw-light"></i>');
        el.innerHTML = emailHTML;
        el.addEventListener("click", (event) => {
            event.preventDefault();
            setTimeout(() => {
                location.href = "mailto:" + email.replace("[at]", "@");
            }, 500);
        });
    });
});